import React, { FC, useState } from 'react';
import cn from '@/app/scripts/components/Params.module.scss';
import { useTranslation } from 'react-i18next';
import { Form, Select, Button, Radio, RadioChangeEvent, Input, Checkbox, Empty } from 'antd';
import { LabeledValue } from 'antd/es/select';
import { inject, observer } from 'mobx-react';
import { ConnectStore } from '@/app/connects/connect.store';
import { Channel } from '@/app/connects/models/channel';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import {
    BaseRuleType,
    ChannelCondition, ChannelRule, ParamsRule,
    ParamsRuleConditionType,
    SysIfReaction, SysIfRule
} from '@/app/scripts/models/reactions/SysIfReaction';
import { uuid } from '@/common/utils/uuid';

type SysIfChannelsConditionProps = {
    channels: Channel[];
    rule: ChannelRule;
};

type SysIfChannelsParamsProps = {
    rule: ParamsRule;
};

type SysIfParamsEditorProps = {
    connectStore?: ConnectStore;
    reaction: SysIfReaction;
};

type SysIfGroupProps = {
    channels: Channel[];
    index: number;
    rule: SysIfRule;
    onRemoveRule: (rule: SysIfRule) => void;
    isDeletable: boolean;
};

const RULES: { label: string; value: BaseRuleType }[] = [{
    label: 'Канал', value: 'channel'
}, {
    label: 'Параметр', value: 'param',
}];

const findRule = (ruleType: BaseRuleType) => RULES.find((rule) => rule.value === ruleType);

const CONDITIONS: { label: string; value: ChannelCondition }[] = [{label: 'Равен', value: 'equals'}, {
    label: 'Не равен',
    value: 'not_equals'
}];
const findCondition = (ruleType: ChannelCondition) => CONDITIONS.find((rule) => rule.value === ruleType);


const PARAMS_CONDITIONS: { label: string; value: ParamsRuleConditionType }[] = [{
    label: 'Заполнен', value: 'exists'
}, {
    label: 'Не заполнен', value: 'not_exists'
}, {
    label: 'Включает в себя', value: 'includes'
}, {
    label: 'Не включает в себя', value: 'not_includes'
}, {
    label: 'Равен', value: 'equals'
}, {
    label: 'Не равен', value: 'not_equals'
}];

const TYPES_OF_VALUE = [{
    label: 'Строка', value: 'string'
}, {
    label: 'Булеан', value: 'boolean'
}, {
    label: 'Число', value: 'number'
}];

const findParamsCondition = (ruleType: ParamsRuleConditionType) => PARAMS_CONDITIONS.find((rule) => rule.value === ruleType);

const findTypeOfValue = (type: string) => TYPES_OF_VALUE.find((typeOfValue) => typeOfValue.value === type);

const findChannel = (channels: Channel[], channelKey?: string) => {
    const channel = channels.find((channel) => channel.key === channelKey);
    if (channel) {
        return {value: channel.key, label: channel.name};
    }
}
export const SysIfChannelsCondition: FC<SysIfChannelsConditionProps> = ({
                                                                            channels, rule
                                                                        }) => {
    const [selectedChannel, setSelectedChannel] = useState<LabeledValue>(findChannel(channels, rule.channel));
    const [selectedCondition, setSelectedCondition] = useState<LabeledValue>(findCondition(rule.condition));
    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const {t} = useTranslation();

    const onSelectChannel = (value: LabeledValue) => {
        rule.channel = value.value as string;
        setSelectedChannel(value);
    }

    const onSelectCondition = (value: LabeledValue) => {
        rule.condition = value.value as ChannelCondition;
        setSelectedCondition(value);
    }

    return <>
        <Form.Item>
            <Select
                onClick={stopPropagation}
                placeholder={t('flows.select_condition')}
                optionFilterProp="children"
                onChange={onSelectCondition}
                labelInValue
                value={selectedCondition}
                options={CONDITIONS}/>
        </Form.Item>
        <Form.Item>
            <Select
                onClick={stopPropagation}
                placeholder={t('flows.select_channel')}
                optionFilterProp="children"
                onChange={onSelectChannel}
                labelInValue
                value={selectedChannel}
                fieldNames={{value: 'key', label: 'name'}}
                options={channels}/>
        </Form.Item>
    </>
}

export const SysIfParamsCondition: FC<SysIfChannelsParamsProps> = ({
                                                                       rule
                                                                   }) => {
    const [selectedParamName, setSelectedParamName] = useState<string>(rule.name);
    const [selectedCondition, setSelectedCondition] = useState<LabeledValue>(findParamsCondition(rule.condition));
    const [selectedParamValue, setSelectedParamValue] = useState<string[] | string>(Array.isArray(rule.value) ? rule.value.map((s) => s.toString()) : rule.value);
    const [selectedTypeValue, setSelectedValue] = useState<LabeledValue>(findTypeOfValue(rule.typeOfValue ?? 'string'));
    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const {t} = useTranslation();

    const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        rule.value = e.target.value as string;
        setSelectedParamValue(e.target.value);
    }

    const onChangeValueArray = (value: string[]) => {
        rule.value = value.map(str => {
            if (isNaN(+str)) {
                return str;
            } else {
                return +str;
            }
        });
        setSelectedParamValue(value);
    }

    const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        rule.name = e.target.value as string;
        setSelectedParamName(e.target.value);
    }

    const onSelectCondition = (value: LabeledValue) => {
        const val = value.value as ChannelCondition;
        if (['exists', 'not_exists'].includes(val)) {
            setSelectedParamValue('');
        }

        rule.condition = val;
        setSelectedCondition(value);
    }

    const onSelectType = (value: LabeledValue) => {
        rule.typeOfValue = value.value as string;
        let mapper = (value: any) => value?.toString();

        if (rule.typeOfValue === 'number') {
            mapper = (value: any) => parseFloat(value);
        } else if (rule.typeOfValue === 'boolean') {
            mapper = (value: any) => value?.toString().toLowerCase() === 'true';
        }

        rule.value = Array.isArray(rule.value) ? rule.value.map(mapper) : mapper(rule.value);

        setSelectedValue(value);
    }

    return <>
        <Form.Item>
            <Input
                allowClear
                onClick={stopPropagation}
                placeholder={t('flows.param_name')}
                onChange={onChangeName}
                value={selectedParamName}/>
        </Form.Item>
        <Form.Item>
            <Select
                onClick={stopPropagation}
                placeholder={t('flows.select_rule')}
                optionFilterProp="children"
                onChange={onSelectCondition}
                labelInValue
                value={selectedCondition}
                options={PARAMS_CONDITIONS}/>
        </Form.Item>
        {['includes', 'not_includes'].includes(selectedCondition?.value as string) ? <Form.Item> <Select
            mode="tags"
            notFoundContent={<Empty description={'Нет данных'} />}
            style={{width: '100%'}}
            placeholder={t('flows.param_value')}
            onChange={onChangeValueArray}
            onClick={(e) => e.stopPropagation()}
            value={selectedParamValue as string[]}
            options={[]}
        /> </Form.Item> : <Form.Item>
            <Input
                disabled={['exists', 'not_exists'].includes(selectedCondition?.value as string)}
                allowClear
                onClick={stopPropagation}
                placeholder={t('flows.param_value')}
                onChange={onChangeValue}
                value={selectedParamValue}/>
        </Form.Item>}

        <Form.Item>
            <Select
                onClick={stopPropagation}
                placeholder={t('flows.select_type')}
                optionFilterProp="children"
                onChange={onSelectType}
                labelInValue
                value={selectedTypeValue}
                options={TYPES_OF_VALUE}/>
        </Form.Item>


    </>
}

export const SysIfGroup: FC<SysIfGroupProps> = observer(({channels, index, rule, onRemoveRule, isDeletable}) => {
    const {t} = useTranslation();
    const [selectedRule, setSelectedRule] = useState<LabeledValue>(findRule(rule.type));

    const clearRule = (rule: SysIfRule) => {
        Object.keys(rule).forEach((key) => {
            delete rule[key as keyof SysIfRule];
        });
    };
    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const onSelectRule = (value: LabeledValue) => {
        const conditionalOperator = rule.conditionalOperator;
        clearRule(rule);
        rule.type = value.value as BaseRuleType;
        rule.conditionalOperator = conditionalOperator;
        setSelectedRule(value);
    };

    return <div className={cn.ruleWrapper}>
        <div className={cn.ruleTitle}>
            <span>Правило {index}</span>
            {isDeletable && <CloseOutlined onClick={(e) => {
                stopPropagation(e);
                onRemoveRule(rule);
            }} className={cn.ruleClose}/>}
        </div>
        <Form.Item>
            <Select
                onClick={stopPropagation}
                placeholder={t('flows.select_rule')}
                optionFilterProp="children"
                onChange={onSelectRule}
                labelInValue
                value={selectedRule}
                options={RULES}/>
        </Form.Item>

        {
            rule.type === 'channel' && <SysIfChannelsCondition rule={rule as ChannelRule} channels={channels}/>
        }

        {
            rule.type === 'param' && <SysIfParamsCondition rule={rule as ParamsRule}/>
        }
    </div>
});
export const SysIfParamsEditor: FC<SysIfParamsEditorProps> = inject('connectStore')(observer(({
                                                                                                  connectStore, reaction
                                                                                              }) => {
    const {t} = useTranslation();
    // const [rules, setRules] = useState([{
    //     conditionalOperator: 'and'
    // }]);

    const addRule = () => {
        reaction.appendRule({type: 'channel', conditionalOperator: 'and'});
    }

    const onSetRuleCondition = (rule: any, e: RadioChangeEvent) => {
        e.stopPropagation();
        rule.conditionalOperator = e.target.value;
        // setRules(rules.slice());
    }

    const onCheck = () => {
        reaction.fail = !reaction.fail;
    }

    return <div className={cn.editor}>
        <div className={cn.group}>
            <h6 className={cn.groupTitle}>
                {t('flows.reactions.sysif')} {reaction.name.split(' ')[1]}
            </h6>

            {reaction.rules.map((rule: any, i: number) => <div key={rule.id ?? uuid()}>
                <SysIfGroup isDeletable={reaction.rules.length > 1} onRemoveRule={() => reaction.removeRule(rule)}
                            rule={rule} channels={connectStore.channels} index={i + 1}/>
                {i !== reaction.rules.length - 1 && <div className={cn.flexCenter}>
                    <Radio.Group options={[{label: 'И', value: 'and'}, {label: 'ИЛИ', value: 'or'}]}
                                 onChange={(e) => onSetRuleCondition(rule, e)} value={rule.conditionalOperator}
                                 optionType="button"/>
                </div>}
            </div>)}
            <div>
                <Button block onClick={addRule} type="default" icon={<PlusOutlined/>}>Добавить правило</Button>
            </div>
            <div style={{marginTop: '10px'}}>
                <Checkbox checked={reaction.fail}
                          value={reaction.fail}
                          onChange={onCheck}/> {t('flows.not_completed')}
            </div>
        </div>
    </div>
}));
