import { computed, makeObservable } from 'mobx';
import { Node } from './Node';
import { Reaction } from './reactions/Reaction';
import { State } from './State';
import { Label } from './Label';
import { Trigger } from './internal';
import { IntentTrigger } from './triggers/IntentTrigger';
import { EntityTrigger } from './triggers/EntityTrigger';
import { SnippetReaction } from './reactions/SnippetReaction';
import { SysIfReaction } from './reactions/SysIfReaction';

export class Flow extends Node {
    static className = 'Flow';
    @computed get reaction() {
        return this.next && Reaction.is(this.next)
            ? this.next as Reaction : null;
    }

    @computed get state() {
        return this.next && State.is(this.next)
            ? this.next as State : null
    }

    private lastReactionNumber: number = 0;
    private lastStateNumber: number = 0;

    constructor() {
        super();
        makeObservable(this);
    }

    public get labels(): Array<Label> {
        const labels: Array<Label> = [];

        for (const item of this.iterate()) {
            if (State.is(item)) {
                const state = item as State;
                labels.push({id: state.id, name: state.name, type: 'state'});
            } else if (Reaction.is(item)) {
                const reaction = item as Reaction;
                labels.push({id: reaction.id, name: reaction.name, type: 'reaction'});
            }
        }

        return labels;
    }

    /**
     * Вызов данного гетера this.lastStateNumber += 1; меняет значение lastStateNumber
     */

    public get nextStateNum(): number {
        if (this.lastStateNumber == 0) {
            let maxNumber = 0;
            for (const item of this.iterate()) {
                if (State.is(item)) {
                    const state = item as State;
                    const number = Number.parseInt(state.name.replace('State ', ''))
                    if (number > maxNumber) {
                        maxNumber = number;
                    }
                }
            }

            this.lastStateNumber = maxNumber;
        }

        this.lastStateNumber += 1;

        return this.lastStateNumber
    }

    /**
     * Вызов данного гетера this.lastReactionNumber += 1; меняет значение lastReactionNumber
     */

    public get nextReactionNum(): number {
        this.lastReactionNumber = this.reduce((value, node) => {
            if (Reaction.is(node)) {
                const reaction = node as Reaction;
                const match = reaction.name.match(/(\d+)/);
                if (!match) {
                    return value;
                }
                const number = Number.parseInt(match[0]);
                if (number > value) {
                    return number;
                }
            }
            return value;
        }, 0);

        return ++this.lastReactionNumber;
    }

    public forEach(func: (node: Node) => void): void {
        for (const item of this.iterate()) {
            func(item);
        }
    }

    public reduce<T>(func: (prev: T, curr: Node) => T, startValue: T) {
        let value = startValue;
        for (const item of this.iterate()) {
            value = func(value, item);
        }
        return value;
    }

    private* iterate(): Iterable<Node> {
        yield this;

        if (this.state) {
            yield* Flow.iterateState(this.state);
        } else if (this.reaction) {
            yield* Flow.iterateReaction(this.reaction);
        }
    }

    private static* iterateNext(next: Node) {
        if (State.is(next)) {
            yield* Flow.iterateState(next as State);
        } else if (Reaction.is(next)) {
            yield* Flow.iterateReaction(next as Reaction);
        }
    }

    private static* iterateState(state: State): Iterable<Node> {
        yield state;
        if (state.triggers.length) {
            yield* Flow.iterateTriggers(state.triggers);
        }
        if (state.fallback) {
            yield* Flow.iterateTriggers([state.fallback]);
        }
    }

    private static* iterateTriggers(triggers: Array<Trigger>): Iterable<Node> {
        for (const trigger of triggers) {
            yield trigger;
            if (trigger.next) {
                yield* Flow.iterateNext(trigger.next);
            }

            if (IntentTrigger.is(trigger)) {
                for (const b of (trigger as IntentTrigger).noEntityBranches) {
                    if (b.next) {
                        yield* Flow.iterateNext(b.next)
                    }
                }
            }

            if (EntityTrigger.is(trigger) && (trigger as EntityTrigger).noEntityNext) {
                const next = (trigger as EntityTrigger).noEntityNext!;
                yield* Flow.iterateNext(next);
            }
        }
    }

    private static* iterateReaction(reaction: Reaction): Iterable<Node> {
        yield reaction;

        if (reaction.next) {
            yield* Flow.iterateNext(reaction.next);
        }

        if ((SnippetReaction.is(reaction) || SysIfReaction.is(reaction)) && (reaction as SnippetReaction).nextFail) {
            yield* Flow.iterateNext((reaction as SnippetReaction).nextFail!);
        }
    }

    validate() {
        this.forEach((item) => {
            if (SysIfReaction.is(item)) {
                const validation = SysIfReaction.validationRule(item as SysIfReaction);
                if (!validation.isValid) {
                    // as server-like error type
                    throw {data: [validation.error]};
                }
            }
        })
    }
}
